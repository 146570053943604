import React, { Component } from "react";
import { _crud } from "../../../utils/crud";

class Solde extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prenom: null,
      nom: null,
      sexe: null,
      username: null,
      password: null,
      telephone: "0",
      role: null,
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  onGet = () => {
    this.setState({
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "collecte", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          List: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          List: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      prenom: this.state.prenom,
      nom: this.state.nom,
      sexe: this.state.sexe,
      username: this.state.username,
      role: this.state.role,
      password: this.state.password,
      telephone: this.state.telephone,
    };

    _crud("post", `user`, datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: false,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
        });
        window.location.assign(`/umf/utilisateurs`);
      } else {
        this.setState({
          message: {
            state: false,
            msg: response,
            type: "danger",
            isloading: false,
          },
          show: true,
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  componentDidMount() {
    this.onGet();
  }
  render() {
    // var { List } = this.state;
    return (
      <>
        <div class="row my-4">
          <div class="col-md-4">
            <div class="card mb-4 shadow">
              <div class="card-body my-n3">
                <div class="row align-items-center">
                  <div class="col-3 text-center">
                    <span class="circle circle-lg bg-light">
                      <i class="fe fe-user fe-24 text-primary"></i>
                    </span>
                  </div>
                  <div class="col">
                    <h3 class="h5 mt-4 mb-1">Personal</h3>
                    <p class="text-muted">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Mauris blandit nisl ullamcorper, rutrum metus in, congue
                      lectus.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <span>Account Settings</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 shadow">
              <div class="card-body my-n3">
                <div class="row align-items-center">
                  <div class="col-3 text-center">
                    <span class="circle circle-lg bg-light">
                      <i class="fe fe-user fe-24 text-primary"></i>
                    </span>
                  </div>
                  <div class="col">
                    <h3 class="h5 mt-4 mb-1">Personal</h3>
                    <p class="text-muted">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Mauris blandit nisl ullamcorper, rutrum metus in, congue
                      lectus.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <span>Account Settings</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Solde;
