import React, { Component } from "react";
import { _crud } from "../../utils/crud";

class Gesfidele extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nomComplet: null,
      sexe: `AUCUN`,
      principal: null,
      secondaire: null,
      etatCivil: `AUCUN`,
      niveauEtude: `AUCUN`,
      profession: null,
      adresse: null,
      commune: `AUCUN`,
      COMMUNES: [
        { id: 1, name: "AUCUN" },
        { id: 2, name: "BANDALUNGWA" },
        { id: 3, name: "BARUMBU" },
        { id: 4, name: "BUMBU" },
        { id: 5, name: "GOMBE" },
        { id: 6, name: "KALAMU" },
        { id: 7, name: "KASA_VUBU" },
        { id: 8, name: "KIMBANSEKE" },
        { id: 9, name: "KINSHASA" },
        { id: 10, name: "KISENSO" },
        { id: 11, name: "LEMBA" },
        { id: 12, name: "LIMETE" },
        { id: 13, name: "LINGWALA" },
        { id: 14, name: "MAKALA" },
        { id: 15, name: "MALUKU" },
        { id: 16, name: "MASINA" },
        { id: 17, name: "MATETE" },
        { id: 18, name: "MONT_NGAFULA" },
        { id: 19, name: "NDJILI" },
        { id: 20, name: "NGABA" },
        { id: 21, name: "NGALIEMA" },
        { id: 22, name: "NGIRI_NGIRI" },
        { id: 23, name: "NSELE" },
        { id: 24, name: "SELEMBAO" },
      ],
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      nomComplet: this.state.nomComplet,
      sexe: this.state.sexe,
      telephone: {
        principal: this.state.principal,
        secondaire: this.state.secondaire,
      },
      etatCivil: this.state.etatCivil,
      niveauEtude: this.state.niveauEtude,
      profession: this.state.profession,
      adresse: this.state.adresse,
      commune: this.state.commune,
    };

    _crud("post", "fidele", datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
        });
        window.location.assign("/talyva/getion-fideles");
      } else {
        this.setState({
          message: {
            state: true,
            msg: response,
            type: "danger",
            isloading: false,
          },
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  render() {
    var { message, COMMUNES } = this.state;
    return (
      <>
        <div className="col-10 mx-auto">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 class="h5 page-title" id="task-section">
                Gestion des Fideles
              </h2>
              <p class="text-muted">Enregistrement fidèle</p>
            </div>
            <a href="/talyva/grille-fideles">Liste de Fidèles</a>
          </div>
          <div class="row">
            <div class="col-md-12 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title">Nouveau Fidèle</strong>
                </div>
                <div class="card-body">
                  <div classNameName="container">
                    {message.state && (
                      <div
                        class={
                          message.type === "danger"
                            ? "alert alert-danger alert-dismissible fade show"
                            : "alert alert-success alert-dismissible fade show"
                        }
                        role="alert"
                      >
                        {message.msg}
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    )}
                  </div>
                  <form onSubmit={this.onPost}>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="nomComplet">Nom complet</label>
                          <input
                            type="text"
                            id="nomComplet"
                            class="form-control"
                            name="nomComplet"
                            placeholder="Nom complet"
                            onChange={(e) => this.handlechange(e)}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="profession">Profession</label>
                          <input
                            type="text"
                            id="profession"
                            class="form-control"
                            name="profession"
                            placeholder="Profession"
                            onChange={(e) => this.handlechange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class="mb-3">
                          <label for="sexe" class="form-label">
                            Sexe
                          </label>
                          <select
                            id="sexe"
                            class="form-control"
                            aria-label="Default select example"
                            name="sexe"
                            onChange={(e) => this.handlechange(e)}
                          >
                            <option value="AUCUN">Sexe</option>
                            <option value="M">Masculin</option>
                            <option value="F">Feminin</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class="mb-3">
                          <label for="etatCivil" class="form-label">
                            Etat-civile
                          </label>
                          <select
                            id="etatCivil"
                            class="form-control"
                            aria-label="Default select example"
                            name="etatCivil"
                            onChange={(e) => this.handlechange(e)}
                          >
                            <option value="AUCUN">Choisir...</option>
                            <option value="CELIBATAIRE">Célibataire</option>
                            <option value="MARIE">Marié</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class="mb-3">
                          <label for="niveauEtude" class="form-label">
                            Niveau d'étude
                          </label>
                          <select
                            id="niveauEtude"
                            class="form-control"
                            aria-label="Default select example"
                            name="niveauEtude"
                            onChange={(e) => this.handlechange(e)}
                          >
                            <option value="AUCUN">Choisir...</option>
                            <option value="PRIMAIRE">PRIMAIRE</option>
                            <option value="D6">D6</option>
                            <option value="G3">G3</option>
                            <option value="L2">L2</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="principal">Téléphone prinscipal</label>
                          <input
                            type="phone"
                            id="principal"
                            class="form-control"
                            name="principal"
                            onChange={(e) => this.handlechange(e)}
                            placeholder="N° téléphone secondaire"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="secondaire">Téléphone secondaire</label>
                          <input
                            type="phone"
                            id="secondaire"
                            class="form-control"
                            name="secondaire"
                            onChange={(e) => this.handlechange(e)}
                            placeholder="N° téléphone secondaire"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label for="adresse">Adresse</label>
                          <input
                            type="text"
                            id="adresse"
                            class="form-control"
                            placeholder="Adresse"
                            name="adresse"
                            onChange={(e) => this.handlechange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="mb-3">
                          <label for="commune" class="form-label">
                            Commune
                          </label>
                          <select
                            id="commune"
                            class="form-control"
                            aria-label="Default select example"
                            name="commune"
                            onChange={(e) => this.handlechange(e)}
                          >
                            <option>Commune</option>
                            {COMMUNES &&
                              COMMUNES.map((x) => {
                                return (
                                  <option key={x.id} value={x.name}>
                                    {x.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={message.isloading}
                        >
                          {message.isloading ? (
                            <>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              {"   "}
                              Patientez
                            </>
                          ) : (
                            <span>Enregistrer</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Gesfidele;
