import React, { Component } from "react";
import { _crud } from "../../utils/crud";
// import csvDownload from "json-to-csv-export";

class Gestflist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      itemList: [],
    };
  }

  onGet = () => {
    this.setState({
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
      itemList: [],
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "fidele", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          List: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
          itemList: response.data,
        });
      } else {
        this.setState({
          List: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
          itemList: [],
        });
      }
    });
  };

  filter = (value) => {
    var fideles = this.state.itemList,
      itemOut = [];
    // eslint-disable-next-line
    fideles.find((item) => {
      // eslint-disable-next-line
      if (RegExp(value, "i").test(item.nomComplet && item.nomComplet)) {
        itemOut.push(item);
      }
    });
    

    if (itemOut.length > 0) {
      this.setState({
        List: {
          datas: itemOut,
          message: "List des fideles",
          state: true,
          isLoading: false,
          empty: false,
        },
        message: {
          state: false,
          msg: null,
          type: null,
        },
      });
    } else {
      this.setState({
        List: {
          datas: itemOut,
          state: true,
          message: "Aucun Admin ne correpond à votre recherche",
          isLoading: false,
        },
        message: {
          state: true,
          msg: "Aucun Admin ne correpond à votre recherche",
          type: "danger",
        },
      });
    }
  };

  filterData = (value) => {
    var data = this.state.itemList;
    data.find(x=> x.nomComplet)
    
  }

  componentDidMount() {
    this.onGet();
  }

  render() {
    var { List } = this.state;
    return (
      <>
        <div className="col-12">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 class="h5 page-title" id="task-section">
                Gestion de Fidèle
              </h2>
              <p class="text-muted">Liste générale de fidèles</p>
            </div>
            <a href="/talyva/getion-fideles">Nouveau Fidele</a>
          </div>
          <div className="row">
            <div class="col-md-12 my-4">
              <div class="card shadow">
                <div class="card-body">
                  <div className=" row card-title mb-3 mt-2 align-items-center">
                    <div className="col-md-6">
                      <h5 class="">Grille de Fidèles</h5>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Recherche..."
                        onKeyUp={(e) => this.filter(e.target.value)}
                        name="name"
                      />
                    </div>
                    {/* <div className="col-md-6">
                      
                    </div> */}
                  </div>

                  <table class="table table-sm">
                    <thead class="thead-dark">
                      <tr>
                        <th>#</th>
                        <th>NOM COMPLET</th>
                        <th>SEXE</th>
                        <th>CONTACT</th>
                        <th>ADRESSE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {List?.datas !== null &&
                        List.state &&
                        List?.datas &&
                        List.datas.map((x, i = 0) => {
                          return (
                            <tr key={x.id}>
                              <td>{i < 10 ? "0" + ++i : ++i}</td>
                              <td>
                                <p class="mb-0 text-secondary">
                                  {x.nomComplet}
                                </p>
                              </td>
                              <td>
                                <p class="text-danger">
                                  {x.sexe === `M`
                                    ? `M`
                                    : x.sexe === `F`
                                    ? `F`
                                    : `Aucun`}
                                </p>
                              </td>
                              <td>
                                <p class="text-primary">
                                  <a href="#0" class="text-info">
                                    {x.telephone.principal
                                      ? x.telephone.principal
                                      : `Pas de téléphone`}
                                  </a>
                                </p>
                              </td>
                              <td class="w-25">
                                <p class="text-secondary">
                                  {!x.adresse && x.commune === `AUCUN`
                                    ? `Aucune adresse`
                                    : !x.adresse && x.commune
                                    ? x.commune
                                    : x.adresse && x.commune === `AUCUN`
                                    ? x.adresse
                                    : `${x.adresse}, ${x.commune}`}
                                </p>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm dropdown-toggle more-horizontal"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="#0">
                                    Détail
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Modification
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Supprimer
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <nav aria-label="Table Paging" class="mb-0 text-muted">
                    <ul class="pagination justify-content-center mb-0">
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Précédent
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          1
                        </a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="#0">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Suivant
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {/* <div class="card shadow">
                <div class="card-body">
                  <div class="toolbar">
                    <form class="form">
                      <div class="form-row">
                        <div class="form-group col-4">
                          <label for="search" class="sr-only">
                            Recherche
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="recherche"
                            value=""
                            placeholder="Recherche..."
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <table class="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>NOM COMPLET</th>
                        <th>SEXE</th>
                        <th>CONTACT</th>
                        <th>ADRESSE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {List.state &&
                        List.datas.map((x, i = 0) => {
                          return (
                            <tr key={x.id}>
                              <td>{i < 10 ? "0" + ++i : ++i}</td>
                              <td>
                                <p class="mb-0 text-muted">
                                  <strong>{x.nomComplet}</strong>
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 text-muted">
                                  {x.sexe === `M`
                                    ? `Masculin`
                                    : x.sexe === `F`
                                    ? `Féminin`
                                    : `Aucun`}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 text-muted">
                                  <a href="#0" class="text-muted">
                                    {x.telephone.principal}
                                  </a>
                                </p>
                              </td>
                              <td class="w-25">
                                <small class="text-muted">{`${x.adresse}, ${x.commune}`}</small>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm dropdown-toggle more-horizontal"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="#0">
                                    Détail
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Modification
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Supprimer
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <nav aria-label="Table Paging" class="mb-0 text-muted">
                    <ul class="pagination justify-content-center mb-0">
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Précédent
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          1
                        </a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="#0">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Suivant
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Gestflist;
