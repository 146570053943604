import React, { Component } from "react";

class OperationList extends Component {
  render() {
    var { List } = this.props;
    return (
      <>
        <table class="table table-borderles">
          <thead class="table-dark">
            <tr>
              <th>#</th>
              <th>Opération</th>
              <th>Montant Minimum</th>
              <th>Date limite</th>
              <th>Souscription</th>
              <th>Statuts</th>
            </tr>
          </thead>
          <tbody>
            {List.state &&
              List.datas &&
              List.datas.map((d, i = 0) => {
                return (
                  <tr key={d.id}>
                    <td>{i < 10 ? `0${++i}` : ++i}</td>
                    <td class="text-left text-capitalize">{d?.description}</td>
                    <td class="mb-0 text-info">
                      {new Intl.NumberFormat().format(d?.montantMin) +
                        " " +
                        d?.devise.toUpperCase()}{" "}
                    </td>

                    <td class="mb-0 text-secondary">
                      {d?.dateLimite
                        ? new Intl.DateTimeFormat("fr-FR", {
                            dateStyle: "medium",
                          }).format(new Date(d.dateLimite))
                        : `AUCUNE`}
                    </td>
                    <td>
                      {d?.Souscription && d?.Souscription.length + " Fideles"}
                    </td>
                    <td>
                      {!d?.dateLimite
                        ? `Encours`
                        : new Date(d.dateLimite) > new Date()
                        ? `Encours`
                        : `Expirée`}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  }
}

export default OperationList;
