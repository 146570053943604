import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      etat: localStorage.getItem("state"),
    };
  }

  setEtat = (e) => {
    localStorage.setItem("state", e);
    this.setState({
      etat: localStorage.getItem("state"),
    });
  };

  init_1 = () => {
    localStorage.setItem("state", this.state.etat);
  };

  componentDidMount() {
    this.init_1();
  }

  render() {
    const { etat } = this.state;

    return (
      <>
        <aside
          class="sidebar-left border-right bg-white shadow"
          id="leftSidebar"
          data-simplebar
        >
          <a
            href="#0"
            class="btn collapseSidebar toggle-btn d-lg-none text-muted ml-2 mt-3"
            data-toggle="toggle"
          >
            <i class="fe fe-x">
              <span class="sr-only"></span>
            </i>
          </a>
          <nav
            class="vertnav navbar navbar-light"
            style={{ background: "#add0a0" }}
          >
            <div class="w-100 mb-4 d-flex">
              <Link
                class="navbar-brand mx-auto mt-2 flex-fill text-center"
                to="/"
              >
                <svg
                  version="1.1"
                  id="logo"
                  class="navbar-brand-img brand-sm"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 120 120"
                  xmlSpace="preserve"
                >
                  <g>
                    <polygon class="st0" points="78,105 15,105 24,87 87,87 	" />
                    <polygon class="st0" points="96,69 33,69 42,51 105,51 	" />
                    <polygon class="st0" points="78,33 15,33 24,15 87,15 	" />
                  </g>
                </svg>
              </Link>
            </div>
            <ul class="navbar-nav flex-fill w-100 mb-2">
              <li class="nav-item dropdown">
                <Link
                  to="/"
                  class=" nav-link"
                  id={etat === "/" ? "hove" : ""}
                  onClick={() => this.setEtat("/")}
                >
                  <i
                    class="fe fe-home fe-16"
                    id={etat === "/" ? "hove" : ""}
                  ></i>
                  <span class="ml-3 item-text" id={etat === "/" ? "hove" : ""}>
                    TALYVA-SOFT
                  </span>
                </Link>
              </li>
            </ul>
            <p class="text-muted nav-heading mt-4 mb-1">
              <span className="text-primary font-weight-bold">Options</span>
            </p>
            <ul class="navbar-nav flex-fill w-100 mb-2">
              <li class="nav-item w-100">
                <Link
                  class="nav-link"
                  to="/talyva/getion-fideles"
                  onClick={() => this.setEtat("/talyva/getion-fideles")}
                  id={etat === "/talyva/getion-fideles" ? "hove" : ""}
                >
                  <i
                    class="fe fe-layers fe-16"
                    id={etat === "/talyva/getion-fideles" ? "hove" : ""}
                  ></i>
                  <span
                    class="ml-3 item-text"
                    id={etat === "/talyva/getion-fideles" ? "hove" : ""}
                  >
                    Gestion de Fidèle
                  </span>
                </Link>
              </li>
              <li class="nav-item dropdown">
                <a
                  href="#ui-elements"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle nav-link"
                >
                  <i class="fe fe-box fe-16"></i>
                  <span class="ml-3 item-text">Souscription & Collecte</span>
                </a>
                <ul class="collapse list-unstyled pl-4 w-100" id="ui-elements">
                  <li class="nav-item">
                    <Link
                      class="nav-link pl-3"
                      to="/talyva/souscription-collecte/souscription"
                      onClick={() =>
                        this.setEtat(
                          "/talyva/souscription-collecte/souscription"
                        )
                      }
                      id={
                        etat === "/talyva/souscription-collecte/souscription"
                          ? "hove"
                          : ""
                      }
                    >
                      <span
                        class="ml-1 item-text"
                        id={
                          etat === "/talyva/souscription-collecte/souscription"
                            ? "hove"
                            : ""
                        }
                      >
                        Souscription
                      </span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link pl-3"
                      to="/talyva/souscription-collecte/collecte"
                      onClick={() =>
                        this.setEtat("/talyva/souscription-collecte/collecte")
                      }
                      id={
                        etat === "/talyva/souscription-collecte/collecte"
                          ? "hove"
                          : ""
                      }
                    >
                      <span
                        class="ml-1 item-text"
                        id={
                          etat === "/talyva/souscription-collecte/collecte"
                            ? "hove"
                            : ""
                        }
                      >
                        Collecte
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a
                  href="#forms"
                  data-toggle="collapse"
                  aria-expanded="false"
                  class="dropdown-toggle nav-link"
                >
                  <i class="fe fe-credit-card fe-16"></i>
                  <span class="ml-3 item-text">Caisse</span>
                </a>
                <ul class="collapse list-unstyled pl-4 w-100" id="forms">
                  <li class="nav-item">
                    <Link
                      class="nav-link pl-3"
                      to="/talyva/situation-caisse/entree"
                      onClick={() =>
                        this.setEtat("/talyva/situation-caisse/entree")
                      }
                      id={
                        etat === "/talyva/situation-caisse/entree" ? "hove" : ""
                      }
                    >
                      <span
                        class="ml-1 item-text"
                        id={
                          etat === "/talyva/situation-caisse/entree"
                            ? "hove"
                            : ""
                        }
                      >
                        Entrée caisse
                      </span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link pl-3"
                      to="/talyva/situation-caisse/sortie"
                      onClick={() =>
                        this.setEtat("/talyva/situation-caisse/sortie")
                      }
                      id={
                        etat === "/talyva/situation-caisse/sortie" ? "hove" : ""
                      }
                    >
                      <span
                        class="ml-1 item-text"
                        id={
                          etat === "/talyva/situation-caisse/sortie"
                            ? "hove"
                            : ""
                        }
                      >
                        Déboursement
                      </span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link pl-3"
                      to="/talyva/situation-caisse/solde"
                      onClick={() =>
                        this.setEtat("/talyva/situation-caisse/solde")
                      }
                      id={
                        etat === "/talyva/situation-caisse/solde" ? "hove" : ""
                      }
                    >
                      <span
                        class="ml-1 item-text"
                        id={
                          etat === "/talyva/situation-caisse/solde"
                            ? "hove"
                            : ""
                        }
                      >
                        Solde
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item w-100">
                <Link
                  class="nav-link"
                  to="/talyva/charge/repartitions"
                  onClick={() => this.setEtat("/talyva/charge/repartitions")}
                  id={etat === "/talyva/charge/repartitions" ? "hove" : ""}
                >
                  <i
                    class="fe fe-layers fe-16"
                    id={etat === "/talyva/charge/repartitions" ? "hove" : ""}
                  ></i>
                  <span
                    class="ml-3 item-text"
                    id={etat === "/talyva/charge/repartitions" ? "hove" : ""}
                  >
                    Repartition de Charges
                  </span>
                </Link>
              </li>
            </ul>
            <p class="text-muted nav-heading mt-4 mb-1">
              <span className="text-primary font-weight-bold">Paramètre</span>
            </p>
            <ul class="navbar-nav flex-fill w-100 mb-2">
              <li class="nav-item w-100">
                <Link
                  class="nav-link"
                  to="/talyva/caisse"
                  id={etat === "/talyva/caisse" ? "hove" : ""}
                  onClick={() => this.setEtat("/talyva/caisse")}
                >
                  <i
                    class="fe fe-folder fe-16"
                    id={etat === "/talyva/caisse" ? "hove" : ""}
                  ></i>
                  <span
                    class="ml-3 item-text"
                    id={etat === "/talyva/caisse" ? "hove" : ""}
                  >
                    Caisse
                  </span>
                </Link>
              </li>
              <li class="nav-item w-100">
                <Link
                  class="nav-link"
                  to="/talyva/taux"
                  id={etat === "/talyva/taux" ? "hove" : ""}
                  onClick={() => this.setEtat("/talyva/taux")}
                >
                  <i
                    class="fe fe-book fe-16"
                    id={etat === "/talyva/taux" ? "hove" : ""}
                  ></i>
                  <span
                    class="ml-3 item-text"
                    id={etat === "/talyva/taux" ? "hove" : ""}
                  >
                    Taux
                  </span>
                </Link>
              </li>
              <li class="nav-item w-100">
                <Link
                  class="nav-link"
                  to="/talyva/operations"
                  id={etat === "/talyva/operations" ? "hove" : ""}
                  onClick={() => this.setEtat("/talyva/operations")}
                >
                  <i
                    class="fe fe-calendar fe-16"
                    id={etat === "/talyva/operations" ? "hove" : ""}
                  ></i>
                  <span
                    class="ml-3 item-text"
                    id={etat === "/talyva/operations" ? "hove" : ""}
                  >
                    Opération
                  </span>
                </Link>
              </li>
              <li class="nav-item w-100">
                <Link
                  class="nav-link"
                  to="/talyva/utilisateur"
                  id={etat === "/talyva/utilisateur" ? "hove" : ""}
                  onClick={() => this.setEtat("/talyva/utilisateur")}
                >
                  <i
                    class="fe fe-user fe-16"
                    id={etat === "/talyva/utilisateur" ? "hove" : ""}
                  ></i>
                  <span
                    class="ml-3 item-text"
                    id={etat === "/talyva/utilisateur" ? "hove" : ""}
                  >
                    Utilisateur
                  </span>
                </Link>
              </li>
            </ul>
          </nav>
        </aside>
      </>
    );
  }
}
