import React, { Component } from "react";
import { _crud } from "../../utils/crud";
import Caisselist from "./caisseList";

class Caisse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devise: null,
      description: null,
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  onGet = () => {
    this.setState({
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "caisse", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          List: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          List: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      devise: this.state.devise,
      description: this.state.description,
    };

    _crud("post", "caisse", datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
        });
        window.location.assign("/talyva/caisse");
      } else {
        this.setState({
          message: {
            state: true,
            msg: response,
            type: "danger",
            isloading: false,
          },
          show: true,
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  componentDidMount() {
    this.onGet();
  }
  render() {
    var { List, message } = this.state;
    return (
      <>
        <div className="col-10 mx-auto">
          <h2 class="h5 page-title" id="task-section">
            Caisse
          </h2>
          <p class="text-muted">
            A jQuery plugin generates sparklines (small inline charts) directly
            in the browser using data supplied either inline in the HTML, or via
            javascript{" "}
          </p>
          <div class="row">
            <div class="col-md-12 my-4">
              <div class="card shadow">
                <div class="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h5 class="card-title">Table caisse</h5>
                    <button
                      class="btn btn-primary float-right ml-3"
                      type="button"
                      data-toggle="modal"
                      data-target="#caisseModal"
                    >
                      Ajout Caisse +
                    </button>
                  </div>
                  <Caisselist List={List} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="caisseModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="defaultModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="defaultModalLabel">
                  Caisse
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.onPost}>
                <div class="modal-body">
                  <div classNameName="container">
                    {message.state && (
                      <div
                        class={
                          message.type === "danger"
                            ? "alert alert-danger alert-dismissible fade show"
                            : "alert alert-success alert-dismissible fade show"
                        }
                        role="alert"
                      >
                        {message.msg}
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="mb-3">
                        <label for="prenom" class="form-label">
                          Devise
                        </label>
                        <select
                          class="form-control"
                          aria-label="Default select example"
                          name="devise"
                          onChange={(e) => this.handlechange(e)}
                        >
                          <option selected>devise...</option>

                          <option value="CDF">Franc Congolais (cdf)</option>
                          <option value="USD">Dollars (usd)</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="mb-3">
                        <label for="nom" class="form-label">
                          Déscription
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="nom"
                          aria-describedby="emailHelp"
                          placeholder="Description"
                          name="description"
                          onChange={(e) => this.handlechange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="reset"
                    class="btn mb-2 btn-danger"
                    data-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    class="btn mb-2 btn-primary"
                    disabled={message.isloading}
                  >
                    {message.isloading ? (
                      <>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {"   "}
                        Patientez
                      </>
                    ) : (
                      <span>Enregistrer</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Caisse;
