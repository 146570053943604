import axios from "axios";
import { IsNotEmpty, _api, _Store } from "./utils";

export const _crud = (
  type = null,
  url = null,
  datas = null,
  callback = null
) => {
  var token = _Store() && _Store() ? _Store().token : null;
  switch (type) {
    case "post":
      if (IsNotEmpty(datas)) {
        axios
          .post(`${_api()}/${url}`, datas, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            if (res.status) {
              callback(true, res.data);
            } else {
              callback(false, res.message);
            }
          })
          .catch((err) => {
            if (err.response) {
              callback(false, err.response.data.message);
            } else {
              callback(false, "Veuillez verifier votre Connexion Internet");
            }
          });
      } else {
        callback(false, "Veuillez renseigner toutes les informations");
      }
      break;
    case "get":
      axios
        .get(`${_api()}/${url}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          if (res.data.state) {
            callback(true, res.data);
          } else {
            callback(false, res.data);
          }
        })
        .catch((err) => {
          if (err.response) {
            callback(false, err.response.data.message);
          } else {
            callback(false, "Veuillez verifier votre Connexion Internet");
          }
        });
      break;
    case "put":
      if (IsNotEmpty(datas)) {
        axios
          .patch(`${_api()}/${url}`, datas, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.status) {
              callback(true, res.data);
            } else {
              callback(false, res.data);
            }
          })
          .catch((err) => {
            if (err.response) {
              callback(false, err.response.data.message);
            } else {
              callback(false, "Veuillez verifier votre Connexion Internet");
            }
          });
      } else {
        callback(false, "Veuillez renseigner toutes les informations");
      }
      break;
    case "delete":
      axios
        .delete(`${_api()}/${url}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          if (res.status) {
            callback(true, res.data);
          } else {
            callback(false, res.data.message);
          }
        })
        .catch((err) => {
          if (err.response) {
            callback(false, err.response.data.message);
          } else {
            callback(false, "Veuillez verifier votre Connexion Internet");
          }
        });
      break;
    default:
      return null;
  }
};
