import React, { Component } from "react";
import Login from "../pages/auth/login";

class Auth extends Component {
  render() {
    return (
      <>
        <div class="wrapper mt-5">
          <Login />
        </div>
      </>
    );
  }
}

export default Auth;
