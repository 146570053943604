import React, { Component } from "react";

class Userlist extends Component {
  render() {
    var { List } = this.props;
    return (
      <>
        <table class="table table-bordered table-hover mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Nom complet</th>
              <th>Role</th>
              <th>Email</th>
              <th>Telephone</th>
              <th>Nom d'utilsateur</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {List.state &&
              List.datas &&
              List.datas.map((x, i = 0) => {
                return (
                  <tr key={x.id}>
                    <td>{i < 10 ? "0" + ++i : ++i}</td>
                    <td>{x.fullname}</td>
                    <td>{x.role}</td>
                    <td>{x.email}</td>
                    <td>{x.phone}</td>
                    <td>{x.username}</td>
                    <td>
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c1"
                          checked=""
                        />
                        <label class="custom-control-label" for="c1"></label>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {List.isloading && "ok"}
            {List.empty && (
              <tr>
                <td rowSpan="7">{List.message}</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }
}

export default Userlist;
