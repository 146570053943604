import React, { Component } from "react";
import { _crud } from "../../../utils/crud";

class CollecteList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date1: null,
      date2: null,
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      TotGenerale: null,
    };
  }

  onGet = () => {
    this.setState({
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
      TotGenerale: null,
    });

    var datas = {
      id: "eff",
    };

    _crud(
      "get",
      `collecte/date/${this.state.date1}/${this.state.date2}`,
      datas,
      (state, response) => {
        if (state) {
          response.data.data.sort((a, b) => {
            if (b.createdAt > a.createdAt) {
              return 1;
            }
            return -1;
          });

          this.setState({
            List: {
              datas: response.data.data,
              message: response.message,
              state: true,
              isLoading: false,
              empty: false,
            },
            TotGenerale: response?.data?.total,
          });
        } else {
          this.setState({
            List: {
              datas: null,
              message: response,
              state: true,
              isLoading: false,
              empty: true,
            },
          });
        }
      }
    );
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  render() {
    var { List, TotGenerale } = this.state;
    return (
      <>
        <div className="col-12">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 class="h5 page-title" id="task-section">
                Paiement souscription
              </h2>
            </div>
            <a href="/talyva/souscription-collecte/collecte">
              Nouveau paiement
            </a>
          </div>
          <div className="row">
            <div class="col-md-12 my-4">
              <div class="card shadow">
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <h5 class="card-title">Grille de paiements</h5>
                      <h6 class="card-title text-dark">
                        Tot. USD :{" "}
                        {new Intl.NumberFormat("de-DE").format(
                          TotGenerale ? TotGenerale?.totalUSD : 0
                        )}
                      </h6>
                      <h6 class="card-title text-dark">
                        Tot. CDF :{" "}
                        {new Intl.NumberFormat("de-DE").format(
                          TotGenerale ? TotGenerale?.totalCDF : 0
                        )}
                      </h6>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-4 p-2">
                          <input
                            type="date"
                            className="form-control "
                            name="date1"
                            onChange={this.handlechange}
                          />
                        </div>
                        <div className="col-md-4 p-2">
                          <input
                            type="date"
                            className="form-control "
                            name="date2"
                            onChange={this.handlechange}
                          />
                        </div>
                        <div className="col-md-4 p-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.onGet()}
                            disabled={List?.isLoading}
                          >
                            {List?.isLoading ? "chargement..." : "Rechercher"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table class="table table-sm">
                    <thead class="thead-dark">
                      <tr>
                        <th>#</th>
                        <th>NOM COMPLET</th>
                        <th>SOUSCRIPTIONS</th>
                        <th>MONTANT PAYE</th>
                        <th>DATE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {List.state &&
                        List.datas !== null &&
                        List.datas.length > 0 &&
                        List.datas.map((x, i = 0) => {
                          return (
                            <tr key={x.id}>
                              <td>{i < 10 ? "0" + ++i : ++i}</td>
                              <td>
                                <p class="mb-0 text-secondary">
                                  {x?.Souscription?.Fidele?.nomComplet}
                                </p>
                              </td>
                              <td>
                                <p class="text-danger">
                                  {x?.Souscription?.Operation?.description}
                                </p>
                              </td>
                              <td>
                                <p class="text-primary">
                                  <a href="#0" class="text-info">
                                    {`${new Intl.NumberFormat().format(
                                      x?.montant
                                    )} ${
                                      x?.devise === `USD`
                                        ? `$`
                                        : x?.devise === `CDF`
                                        ? `Fc`
                                        : `Aucune Devise`
                                    }`}
                                  </a>
                                </p>
                              </td>
                              <td>
                                <p class="text-primary">
                                  <a href="#0" class="text-secondary">
                                    {new Intl.DateTimeFormat("fr-FR", {
                                      dateStyle: "long",
                                    }).format(new Date(x.createdAt))}
                                  </a>
                                </p>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm dropdown-toggle more-horizontal"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="#0">
                                    Détail
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Modification
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Supprimer
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colSpan="6" className="text-center">
                          <h3 className="text-danger">
                            {List?.empty && List?.message}
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <nav aria-label="Table Paging" class="mb-0 text-muted">
                    <ul class="pagination justify-content-center mb-0">
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Précédent
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          1
                        </a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="#0">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Suivant
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {/* <div class="card shadow">
                <div class="card-body">
                  <div class="toolbar">
                    <form class="form">
                      <div class="form-row">
                        <div class="form-group col-4">
                          <label for="search" class="sr-only">
                            Recherche
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="recherche"
                            value=""
                            placeholder="Recherche..."
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <table class="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>NOM COMPLET</th>
                        <th>SEXE</th>
                        <th>CONTACT</th>
                        <th>ADRESSE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {List.state &&
                        List.datas.map((x, i = 0) => {
                          return (
                            <tr key={x.id}>
                              <td>{i < 10 ? "0" + ++i : ++i}</td>
                              <td>
                                <p class="mb-0 text-muted">
                                  <strong>{x.nomComplet}</strong>
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 text-muted">
                                  {x.sexe === `M`
                                    ? `Masculin`
                                    : x.sexe === `F`
                                    ? `Féminin`
                                    : `Aucun`}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 text-muted">
                                  <a href="#0" class="text-muted">
                                    {x.telephone.principal}
                                  </a>
                                </p>
                              </td>
                              <td class="w-25">
                                <small class="text-muted">{`${x.adresse}, ${x.commune}`}</small>
                              </td>
                              <td>
                                <button
                                  class="btn btn-sm dropdown-toggle more-horizontal"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span class="text-muted sr-only">Action</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="#0">
                                    Détail
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Modification
                                  </a>
                                  <a class="dropdown-item" href="#0">
                                    Supprimer
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <nav aria-label="Table Paging" class="mb-0 text-muted">
                    <ul class="pagination justify-content-center mb-0">
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Précédent
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          1
                        </a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="#0">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#0">
                          Suivant
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CollecteList;
