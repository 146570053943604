import React, { Component } from "react";
import { _crud } from "../../utils/crud";
import Userlist from "./userList";

class Utilisateur extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: null,
      email: null,
      role: null,
      phone: null,
      username: null,
      Password: null,
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  onGet = () => {
    this.setState({
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "user", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          List: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          List: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      fullname: this.state.fullname,
      email: this.state.email,
      role: this.state.role,
      phone: this.state.phone,
    };

    _crud("post", `user`, datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
          username: response.data.username && response.data.username,
          password: response.data.password && response.data.password,
        });
        // window.location.assign("/talyva/utilisateur");
      } else {
        this.setState({
          message: {
            state: true,
            msg: response,
            type: "danger",
            isloading: false,
          },
          show: true,
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  componentDidMount() {
    this.onGet();
  }
  render() {
    var { List, message, username, password } = this.state;
    return (
      <>
        <div className="col-12">
          <h2 class="h5 page-title" id="task-section">
            Utilisateurs
          </h2>
          <p class="text-muted">
            Tous les applications de l'application talyva.
          </p>
          <div class="row">
            <div class="col-md-12 my-4">
              <div class="card shadow">
                <div class="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h5 class="card-title">Table utilisateurs</h5>
                    <button
                      class="btn btn-primary float-right ml-3"
                      type="button"
                      data-toggle="modal"
                      data-target="#userModal"
                    >
                      Ajout utilisateur +
                    </button>
                  </div>
                  <Userlist List={List} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade bd-example-modal-md"
          id="userModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="defaultModalLabel">
                  Utilisateur
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form onSubmit={this.onPost}>
                <div class="modal-body">
                  <div classNameName="container">
                    {message.state && (
                      <div
                        class={
                          message.type === "danger"
                            ? "alert alert-danger alert-dismissible fade show"
                            : "alert alert-success alert-dismissible fade show"
                        }
                        role="alert"
                      >
                        {message.msg}
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    )}
                    {message.state && message.type === "success" && (
                      <>
                        <p>Nom d'utilisateur : {username}</p>
                        <p>Mot de passe : {password}</p>
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="mb-3">
                        <label for="fullname" class="form-label">
                          Nom complet
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="fullname"
                          aria-describedby="emailHelp"
                          placeholder="Nom complet utilisateur "
                          name="fullname"
                          onChange={(e) => this.handlechange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="mb-3">
                        <label for="nom" class="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          placeholder="Adresse email"
                          name="email"
                          onChange={(e) => this.handlechange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="role" class="form-label">
                          Role
                        </label>
                        <select
                          id="role"
                          class="form-control"
                          aria-label="Default select example"
                          name="role"
                          onChange={(e) => this.handlechange(e)}
                        >
                          <option selected>Role</option>
                          <option value="ADMIN">Admin</option>
                          <option value="CAISSIER">Caissier</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3">
                        <label for="phone" class="form-label">
                          Telephone
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="phone"
                          aria-describedby="emailHelp"
                          placeholder="N° téléphone"
                          name="phone"
                          onChange={(e) => this.handlechange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn mb-2 btn-info"
                    onClick={() => window.location.reload()}
                    data-dismiss="modal"
                  >
                    Fermer
                  </button>
                  <button
                    type="submit"
                    class="btn mb-2 btn-primary"
                    disabled={message.isloading}
                  >
                    {message.isloading ? (
                      <>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {"   "}
                        Patientez
                      </>
                    ) : (
                      <span>Enregistrer</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Utilisateur;
