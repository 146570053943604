import React, { Component } from "react";
import { _Store, _logout } from "../utils/utils";

export default class Topbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: _Store() && _Store(),
    };
  }

  render() {
    var { user } = this.state;
    return (
      <>
        <nav class="topnav navbar navbar-light">
          <button
            type="button"
            class="navbar-toggler text-muted mt-2 p-0 mr-3 collapseSidebar"
          >
            <i class="fe fe-menu navbar-toggler-icon"></i>
          </button>
          <ul class="nav">
            {/* <li class="nav-item">
              <a
                class="nav-link text-muted my-2"
                href="#"
                id="modeSwitcher"
                data-mode="light"
              >
                <i class="fe fe-sun fe-16"></i>
              </a>
            </li>*/}
            <li class="nav-item">
              <a
                class="nav-link text-muted my-2"
                href="index-2.html#"
                data-toggle="modal"
                data-target=".modal-shortcut"
              >
                <span class="fe fe-grid fe-16"></span>
              </a>
            </li>
            {/*<li class="nav-item nav-notif">
              <a
                class="nav-link text-muted my-2"
                href="index-2.html#"
                data-toggle="modal"
                data-target=".modal-notif"
              >
                <span class="fe fe-bell fe-16"></span>
                <span class="dot dot-md bg-success"></span>
              </a>
            </li>*/}
            <li class="nav-item mt-3">
              <h6>
                {user.fullname + " "}
                {`(${user.role})`}
              </h6>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle text-muted pr-0"
                href="#0"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="avatar avatar-sm mt-2">
                  <img
                    src="/assets/avatars/face-1.jpg"
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a class="dropdown-item" href="/auth/profile">
                  Mon profile
                </a>
                <a class="dropdown-item" href="#0" onClick={_logout}>
                  Déconnexion
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}
