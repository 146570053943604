import React, { Component } from "react";
import Aside from "../components/Aside";
import Topbar from "../components/Topbar";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "../pages/admin";
import { PrivateRoute } from "../utils/routes";
import Gesfidele from "../pages/admin/gesFidele";
import Operation from "../pages/admin/Operation";
import Caisse from "../pages/admin/caisse";
import Taux from "../pages/admin/taux";
import Utilisateur from "../pages/admin/utilisateur";
import Entreecaisse from "../pages/admin/SItuationCaisse/entreeCaisse";
import Sortiecaisse from "../pages/admin/SItuationCaisse/sortieCaisse";
import Solde from "../pages/admin/SItuationCaisse/solde";
import Collecte from "../pages/admin/Sousc&Collecte/collecte";
import Souscription from "../pages/admin/Sousc&Collecte/souscription";
import Gestflist from "../pages/admin/gestfList";
import SouscriptionList from "../pages/admin/Sousc&Collecte/sourscList";
import CollecteList from "../pages/admin/Sousc&Collecte/collectList";
import Repartition from "../pages/admin/charges/Repartition";
import Profile from "../pages/auth/profile";
import CheckCollecte from "../pages/admin/Sousc&Collecte/checkCollecte";

class Admin extends Component {
  render() {
    return (
      <>
        <Router>
          <div className="wrapper">
            <Topbar />
            <Aside />
            <main role="main" class="main-content">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <Switch>
                    <PrivateRoute
                      path="/talyva/getion-fideles"
                      component={Gesfidele}
                    />
                    <PrivateRoute
                      path="/talyva/grille-fideles"
                      component={Gestflist}
                    />
                    <PrivateRoute
                      path="/talyva/operations"
                      component={Operation}
                    />
                    <PrivateRoute path="/talyva/caisse" component={Caisse} />
                    <PrivateRoute path="/talyva/taux" component={Taux} />
                    <PrivateRoute
                      path="/talyva/utilisateur"
                      component={Utilisateur}
                    />
                    <PrivateRoute path="/talyva/caisse" component={Caisse} />
                    <PrivateRoute
                      path="/talyva/situation-caisse/entree"
                      component={Entreecaisse}
                    />

                    <PrivateRoute
                      path="/talyva/situation-caisse/sortie"
                      component={Sortiecaisse}
                    />
                    <PrivateRoute
                      path="/talyva/situation-caisse/solde"
                      component={Solde}
                    />
                    <PrivateRoute
                      path="/talyva/souscription-collecte/collecte"
                      component={Collecte}
                    />
                    <PrivateRoute
                      path="/talyva/souscription-collecte/souscription"
                      component={Souscription}
                    />
                    <PrivateRoute
                      path="/talyva/souscription/list"
                      component={SouscriptionList}
                    />
                    <PrivateRoute
                      path="/talyva/collecte/list"
                      component={CollecteList}
                    />
                    <PrivateRoute
                      path="/talyva/charge/repartitions"
                      component={Repartition}
                    />
                    <PrivateRoute path="/auth/profile" component={Profile} />
                    <PrivateRoute
                      path="/check/collecte"
                      component={CheckCollecte}
                    />
                    <PrivateRoute path="/" component={Home} />
                  </Switch>
                </div>
              </div>
            </main>
          </div>
        </Router>
      </>
    );
  }
}

export default Admin;
