import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import auth from "./layouts/auth";
import admin from "./layouts/admin";
import { LoginRoute, PrivateRoute } from "./utils/routes";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <LoginRoute path="/login" component={auth} />
          <PrivateRoute path="/" component={admin} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
