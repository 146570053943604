import React, { Component } from "react";
import { _crud } from "../../../utils/crud";

export default class Repartition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      operationId: null,
      description: null,
      pourcentage: null,
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
    };
  }

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      operationId: this.state.operationId,
      charge: [
        {
          description: "Fonctionnement",
          pourcentage: 60,
        },
        {
          description: "Prise en charge Pasteurs",
          pourcentage: 40,
        },
      ],
    };

    _crud("post", `charge`, datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
        });
        window.location.assign(`/talyva/situation-caisse/entree`);
      } else {
        this.setState({
          message: {
            state: true,
            msg: response,
            type: "danger",
            isloading: false,
          },
          show: true,
        });
      }
    });
  };

  onGet = () => {
    this.setState({
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "operation", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          List: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          List: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  addToStore = () => {
    var old =
      localStorage.getItem("charge") &&
      JSON.parse(localStorage.getItem("charge"));

    const charge = [
      ...old,
      {
        description: this.state.description,
        pourcentage: this.state.pourcentage,
      },
    ];

    localStorage.setItem("charge", JSON.stringify(charge));

    this.setState({
      description: "",
      pourcentage: "",
    });

    console.log(JSON.parse(localStorage.getItem("charge")));
  };

  componentDidMount() {
    this.onGet();
  }

  render() {
    var { List, message, description, operationId, pourcentage } = this.state;

    return (
      <>
        <div className="col-10 mx-auto">
          <h2 class="h5 page-title" id="task-section">
            Repartion de Charges
          </h2>
          <p class="text-muted">
            A jQuery plugin generates sparklines (small inline charts) directly
            in the browser using data supplied either inline in the HTML, or via
            javascript{" "}
          </p>

          <div className="row">
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title">Entree Caisse</strong>
                </div>
                <form onSubmit={this.onPost}>
                  <div class="card-body">
                    <div classNameName="container">
                      {message.state && (
                        <div
                          class={
                            message.type === "danger"
                              ? "alert alert-danger alert-dismissible fade show"
                              : "alert alert-success alert-dismissible fade show"
                          }
                          role="alert"
                        >
                          {message.msg}
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-3">
                        <label
                          for="operationId"
                          class="form-label font-weight-bold "
                        >
                          Opération
                        </label>
                        <select
                          name="operationId"
                          id="operationId"
                          className="form-control"
                          onChange={(e) => this.handlechange(e)}
                          value={operationId}
                        >
                          {List.state &&
                            List.datas.map((x) => {
                              return (
                                <option value={x?.id} key={x?.id}>
                                  {x?.description + " (" + x?.devise + ")"}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <hr />
                    <div className="row mx-1">
                      <div class="col-md-12">
                        <div class="form-group mb-3">
                          <label
                            for="description"
                            class="form-label font-weight-bold "
                          >
                            Déscription
                          </label>

                          <textarea
                            class="form-control"
                            id="description"
                            rows="3"
                            name="description"
                            placeholder="description..."
                            onChange={this.handlechange}
                            value={description}
                          ></textarea>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group mb-3">
                          <label
                            for="pourcentage"
                            class="form-label font-weight-bold "
                          >
                            Pourcentage
                          </label>
                          <input
                            type="number"
                            id="pourcentage"
                            class="form-control"
                            name="pourcentage"
                            placeholder="pourcentage..."
                            onChange={this.handlechange}
                            value={pourcentage}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-link"
                        onClick={() => this.addToStore()}
                      >
                        Ajouter
                      </button>
                    </div>

                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger">
                        Annuler
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        disabled={message.isloading}
                      >
                        {message.isloading ? (
                          <>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {"   "}
                            Patientez
                          </>
                        ) : (
                          <span>Valider</span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title text-uppercase">
                    Détail Opération
                  </strong>
                </div>
                <div class="card-body">
                  <div class="row p-10"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
