import React, { Component } from "react";

class Caisselist extends Component {
  render() {
    var { List } = this.props;
    return (
      <>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Devise</th>
              <th>Description</th>
              <th>Solde</th>
            </tr>
          </thead>
          <tbody>
            {List.state &&
              List.datas &&
              List.datas.map((x, i = 0) => {
                return (
                  <tr key={x.id}>
                    <td>{i < 10 ? "0" + ++i : ++i}</td>
                    <td>{x?.devise}</td>
                    <td>{x?.description && x?.description}</td>
                    <td>{x?.solde && x?.solde}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  }
}

export default Caisselist;
