import React, { Component } from "react";

class Tauxlist extends Component {
  render() {
    var { List } = this.props;
    return (
      <>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Devise I</th>
              <th>Devise II</th>
              <th>Taux</th>
            </tr>
          </thead>
          <tbody>
            {List.state &&
              List.datas &&
              List.datas.map((x, i = 0) => {
                return (
                  <tr key={x.id}>
                    <td>{i < 10 ? "0" + ++i : ++i}</td>
                    <td>{x?.firstDevise}</td>
                    <td>{x?.secondDevise}</td>
                    <td>
                      <span class="badge badge-pill badge-success">
                        {x?.taux}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  }
}

export default Tauxlist;
