import React, { Component } from "react";
import { _crud } from "../../../utils/crud";

class Sortiecaisse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motif: null,
      montant: null,
      devise: null,
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      motif: this.state.motif,
      montant: parseInt(this.state.montant),
      devise: this.state.devise,
    };

    _crud("post", `sortie`, datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
        });
        window.location.assign(`/talyva/situation-caisse/sortie`);
      } else {
        this.setState({
          message: {
            state: true,
            msg: response,
            type: "danger",
            isloading: false,
          },
          show: true,
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  render() {
    var { message, motif, montant, devise } = this.state;
    const ListDevise = [
      {
        label: "Francs congolais",
        value: "CDF",
      },
      {
        label: "Dollars Américain",
        value: "USD",
      },
    ];
    return (
      <>
        <div className="col-12">
          <h2 class="h5 page-title" id="task-section">
            Sortie Caisse
          </h2>
          <p class="text-muted">
            A jQuery plugin generates sparklines (small inline charts) directly
            in the browser using data supplied either inline in the HTML, or via
            javascript{" "}
          </p>
          <div className="row">
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title">Sortie Caisse</strong>
                </div>
                <form action="">
                  <div class="card-body">
                    <div classNameName="container">
                      {message.state && (
                        <div
                          class={
                            message.type === "danger"
                              ? "alert alert-danger alert-dismissible fade show"
                              : "alert alert-success alert-dismissible fade show"
                          }
                          role="alert"
                        >
                          {message.msg}
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-3">
                        <label for="motif" class="form-label font-weight-bold ">
                          Motif
                        </label>
                        <input
                          type="text"
                          id="motif"
                          class="form-control"
                          name="motif"
                          placeholder="motif d'entree"
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                    <div className="row mx-1">
                      <div class="col-md-8">
                        <div class="form-group mb-3">
                          <label
                            for="montant"
                            class="form-label font-weight-bold "
                          >
                            Montant
                          </label>
                          <input
                            type="number"
                            id="montant"
                            class="form-control"
                            name="montant"
                            placeholder="montant..."
                            onChange={this.handlechange}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-3">
                          <label
                            for="devise"
                            class="form-label font-weight-bold "
                          >
                            Devise
                          </label>
                          <select
                            id="devise"
                            class="form-control"
                            name="devise"
                            onChange={this.handlechange}
                          >
                            {ListDevise &&
                              ListDevise.map((x) => {
                                return (
                                  <option key={x.value} value={x.value}>
                                    {x.label}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger">
                        Annuler
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        disabled={message.isloading}
                      >
                        {message.isloading ? (
                          <>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {"   "}
                            Patientez
                          </>
                        ) : (
                          <span>Valider</span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title text-uppercase">
                    Détail Opération
                  </strong>
                </div>
                <div class="card-body">
                  <div class="row p-10">
                    <div className="col-md-12">
                      <div class="mb-3">
                        <label
                          for="fideleId"
                          class="form-label font-weight-bold"
                        >
                          Motif :
                        </label>
                        <p class="text-info">{motif}</p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="prenom" class="form-label font-weight-bold">
                          Montant :
                        </label>
                        <p class="text-danger">
                          {montant} <span className="text-info">{devise}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Sortiecaisse;
