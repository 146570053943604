import React, { Component } from "react";
import { _crud } from "../../../utils/crud";
import Select from "react-select";

class Souscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fideleId: "null",
      operationId: "null",
      montant: 0,
      fi: null,
      op: null,
      options: [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
      ],
      fList: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      oList: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  onGetFideles = () => {
    this.setState({
      fList: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "fidele", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          fList: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          fList: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  onGetOps = () => {
    this.setState({
      oList: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "operation", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          oList: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          oList: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      fideleId: this.state.fi !== null && this.state?.fi?.value,
      operationId: this.state.op !== null && this.state?.op?.value,
      montant: parseInt(this.state.montant),
    };

    _crud("post", `souscription`, datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
        });
        window.location.assign(`/talyva/souscription-collecte/souscription`);
      } else {
        this.setState({
          message: {
            state: true,
            msg: response,
            type: "danger",
            isloading: false,
          },
          show: true,
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  handleFiChange = (fi) => {
    this.setState({ fi });
  };

  handleOpChange = (op) => {
    this.setState({ op });
  };

  componentDidMount() {
    this.onGetFideles();
    this.onGetOps();
  }
  render() {
    var { fList, oList, message, fi, op, montant } = this.state;

    const OptFi =
      fList.datas &&
      fList.state &&
      fList?.datas.length > 0 &&
      fList?.datas.map((x) => {
        return {
          value: x?.id,
          label: x?.nomComplet,
        };
      });

    const OpOp =
      oList.datas &&
      oList.state &&
      oList?.datas.length > 0 &&
      oList?.datas.map((x) => {
        return {
          value: x?.id,
          label: x?.description + " " + x?.montantMin + " " + x?.devise,
        };
      });
    console.log(OptFi);

    return (
      <>
        <div className="col-10 mx-auto">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 class="h5 page-title" id="task-section">
                GESTION DES SOUSCRIPTIONS
              </h2>
              {/* <p class="text-muted">Enregistrement d'une souscrription</p> */}
            </div>
            <a href="/talyva/souscription/list">Liste de souscriptions</a>
          </div>

          {/* <h2 class="h5 page-title" id="task-section">
            GESTION DES SOUSCRIPTIONS
          </h2> */}
          <p class="text-muted">
            Lorsqu'un homme fera un voeu à l'Éternel, ou un serment pour se lier
            par un engagement, il ne violera point sa parole, il agira selon
            tout ce qui est sorti de sa bouche.{" "}
          </p>
          <div class="row">
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title">SOUSCRIPTION</strong>
                </div>
                <form onSubmit={this.onPost}>
                  <div class="card-body">
                    <div classNameName="container">
                      {message.state && (
                        <div
                          class={
                            message.type === "danger"
                              ? "alert alert-danger alert-dismissible fade show"
                              : "alert alert-success alert-dismissible fade show"
                          }
                          role="alert"
                        >
                          {message.msg}
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div class="row p-10">
                      <div className="col-md-12">
                        <div class="mb-3">
                          <label
                            for="fideleId"
                            class="form-label font-weight-bold"
                          >
                            Fidèle
                          </label>
                          <Select
                            value={fi}
                            onChange={this.handleFiChange}
                            options={OptFi && OptFi}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="mb-3">
                          <label
                            for="fideleId"
                            class="form-label font-weight-bold"
                          >
                            Opération à souscrire
                          </label>
                          <Select
                            value={op}
                            onChange={this.handleOpChange}
                            options={OpOp && OpOp}
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-3">
                          <label
                            for="example-helping"
                            class="form-label font-weight-bold "
                          >
                            Montant
                          </label>
                          <input
                            type="number"
                            id="example-helping"
                            class="form-control"
                            name="montant"
                            placeholder="0"
                            onChange={this.handlechange}
                          />
                         
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary position-left"
                        disabled={message.isloading}
                      >
                        {message.isloading ? (
                          <>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {"   "}
                            Patientez
                          </>
                        ) : (
                          <span>Enregistrer</span>
                        )}
                      </button>
                    </div>
                    {/* <div class="col-md-4">
                        <button
                          type="submit"
                          class="btn mb-2 btn-primary position-left"
                          disabled={message.isloading}
                        >
                          {message.isloading ? (
                            <>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              {"   "}
                              Patientez
                            </>
                          ) : (
                            <span>Enregistrer</span>
                          )}
                        </button>
                      </div> */}
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title text-uppercase">
                    Détail souscription
                  </strong>
                </div>
                <form onSubmit={this.onPost}>
                  <div class="card-body">
                    <div class="row p-10">
                      <div className="col-md-12">
                        <div class="mb-3">
                          <label
                            for="fideleId"
                            class="form-label font-weight-bold"
                          >
                            Fidèle :
                          </label>
                          <p class="text-info">{fi?.label}</p>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-3">
                          <label
                            for="prenom"
                            class="form-label font-weight-bold"
                          >
                            Opération :
                          </label>
                          <p class="text-info">{op?.label}</p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group mb-3">
                          <label
                            for="example-helping"
                            class="form-label font-weight-bold"
                          >
                            Montant souscrit :
                          </label>
                          <p class="text-danger">{montant}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Souscription;
