import React, { Component } from "react";
import { _crud } from "../../../utils/crud";
import Select from "react-select";

class Collecte extends Component {
  constructor(props) {
    super(props);

    this.state = {
      souscriptionId: "",
      montant: 0,
      date: null,
      devise: "CDF",
      fi: null,
      su: null,
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },

      fList: {
        datas: [],
        message: null,
        state: false,
        isLoading: false,
        empty: false,
      },
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  onfGet = () => {
    this.setState({
      fList: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "fidele", datas, (state, response) => {
      if (state) {
        response.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          fList: {
            datas: response.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          fList: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  onGet = () => {
    this.setState({
      List: {
        datas: [],
        message: null,
        state: false,
        isLoading: true,
        empty: false,
      },
    });

    var datas = {
      id: "eff",
    };

    _crud("get", "souscription", datas, (state, response) => {
      if (state) {
        response.data.data.sort((a, b) => {
          if (b.createdAt > a.createdAt) {
            return 1;
          }
          return -1;
        });

        this.setState({
          List: {
            datas: response.data.data,
            message: response.message,
            state: true,
            isLoading: false,
            empty: false,
          },
        });
      } else {
        this.setState({
          List: {
            datas: null,
            message: response,
            state: true,
            isLoading: false,
            empty: true,
          },
        });
      }
    });
  };

  onPost = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      souscriptionId: this.state.su !== null && this.state.su?.value,
      montant: parseFloat(this.state.montant),
      devise: this.state.devise,
      date: this.state.date,
    };

    _crud("post", `collecte`, datas, (state, response) => {
      if (state) {
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
          show: true,
        });
        window.location.assign(`/talyva/souscription-collecte/collecte`);
      } else {
        this.setState({
          message: {
            state: true,
            msg: response?.clientVersion ? "Problemme Technique ..." : response,
            type: "danger",
            isloading: false,
          },
          show: true,
        });
      }
    });
  };

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  handleFiChange = (fi) => {
    this.setState({ fi });
  };

  handleSuChange = (su) => {
    this.setState({ su });
  };

  componentDidMount() {
    this.onGet();
    this.onfGet();
  }
  render() {
    var { fList, su, List, date, devise, message, fi, montant } = this.state;

    const OptFi =
      fList.datas &&
      fList.state &&
      fList.datas.length > 0 &&
      fList.datas.map((x) => {
        return {
          value: x?.id,
          label: x?.nomComplet,
        };
      });

    const details =
      List.datas &&
      List.state &&
      List.datas.length > 0 &&
      List.datas.filter((x) => {
        return fi !== null && fi.value === x?.Fidele?.id;
      });

    const OpSous =
      details &&
      details.map((x) => {
        return {
          label:
            x.Operation?.description.toUpperCase() +
            " " +
            x?.montant +
            " " +
            x?.Operation?.devise,
          value: x?.id,
        };
      });

    const ListDevise = [
      {
        label: "Francs congolais",
        value: "CDF",
      },
      {
        label: "Dollars Américain",
        value: "USD",
      },
    ];
    return (
      <>
        <div className="col-10 mx-auto">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 class="h5 page-title" id="task-section">
                Réglèment souscription
              </h2>
            </div>
            <a href="/talyva/collecte/list">Liste de paiements</a>
          </div>
          {/* <h2 class="h5 page-title" id="task-section">
            Réglèment souscription
          </h2>
          <p class="text-muted">
            Lorsqu'un homme fera un voeu à l'Éternel, ou un serment pour se lier
            par un engagement, il ne violera point sa parole, il agira selon
            tout ce qui est sorti de sa bouche.{" "}
          </p> */}
          <div class="row">
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title">PAIEMENT</strong>
                </div>
                <form onSubmit={this.onPost}>
                  <div class="card-body">
                    <div classNameName="container">
                      {message.state && (
                        <div
                          class={
                            message.type === "danger"
                              ? "alert alert-danger alert-dismissible fade show"
                              : "alert alert-success alert-dismissible fade show"
                          }
                          role="alert"
                        >
                          {message.msg}
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div class="row p-10">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label
                            for="fideleId"
                            class="form-label font-weight-bold"
                          >
                            Fidèle
                          </label>
                          {/*<select
                            class="form-control"
                            aria-label="Default select example"
                            name="souscriptionId"
                            onChange={this.handlechange}
                          >
                            <option>Souscription...</option>
                            {List.state &&
                              List.datas.length > 0 &&
                              List.datas.map((x) => {
                                return (
                                  <option value={x?.id} key={x?.id}>
                                    {x?.Fidele?.nomComplet}
                                  </option>
                                );
                              })}
                            </select>*/}
                          <Select
                            value={fi}
                            onChange={this.handleFiChange}
                            options={OptFi && OptFi}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            for="fideleId"
                            class="form-label font-weight-bold"
                          >
                            Souscription à régler
                          </label>
                          <Select
                            value={su}
                            onChange={this.handleSuChange}
                            options={OpSous && OpSous}
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group mb-3">
                          <label
                            for="example-helping"
                            class="form-label font-weight-bold "
                          >
                            Montant
                          </label>
                          <input
                            type="number"
                            id="example-helping"
                            class="form-control"
                            name="montant"
                            placeholder="montant"
                            onChange={this.handlechange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label
                            for="date"
                            class="form-label font-weight-bold "
                          >
                            Date
                          </label>
                          <input
                            type="date"
                            id="date"
                            class="form-control"
                            name="date"
                            placeholder="date"
                            onChange={this.handlechange}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-3">
                          <label
                            for="devise"
                            class="form-label font-weight-bold "
                          >
                            Devise
                          </label>
                          <select
                            id="devise"
                            class="form-control"
                            name="devise"
                            onChange={this.handlechange}
                          >
                            {ListDevise &&
                              ListDevise.map((x) => {
                                return (
                                  <option key={x.value} value={x.value}>
                                    {x.label}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="reset" class="btn btn-danger">
                        Annuler
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        disabled={message.isloading}
                      >
                        {message.isloading ? (
                          <>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {"   "}
                            Patientez
                          </>
                        ) : (
                          <span>Valider</span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 my-4">
              <div class="card shadow mb-4">
                <div class="card-header">
                  <strong class="card-title text-uppercase">
                    Détail Paiement
                  </strong>
                </div>
                <div class="card-body">
                  <div class="row p-10">
                    <div className="col-md-12">
                      <div class="mb-3">
                        <label
                          for="fideleId"
                          class="form-label font-weight-bold"
                        >
                          Fidèle :
                        </label>
                        <p class="text-info">{fi?.label}</p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="prenom" class="form-label font-weight-bold">
                          Souscription :
                        </label>
                        <p class="text-info">{su?.label}</p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group mb-3">
                        <label
                          for="example-helping"
                          class="form-label font-weight-bold"
                        >
                          Date : <span class="text-info">{date}</span>
                        </label>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group mb-3">
                        <label
                          for="example-helping"
                          class="form-label font-weight-bold"
                        >
                          Montant :
                        </label>
                        <p class="text-danger">
                          {montant} <span className="text-info">{devise}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Collecte;
