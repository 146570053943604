import React, { Component } from "react";

class CheckCollecte extends Component {
  render() {
    return (
      <>
        <p>Checking Collecte</p>
      </>
    );
  }
}

export default CheckCollecte;
