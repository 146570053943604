import React, { Component } from "react";
import { _crud } from "../../utils/crud";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      password: null,
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: false,
      },
    };
  }

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : null });
  };

  onLogin = (e) => {
    e.preventDefault();

    this.setState({
      message: {
        state: false,
        msg: null,
        type: null,
        isloading: true,
      },
    });

    var datas = {
      username: this.state.username,
      password: this.state.password,
    };

    _crud("post", "login", datas, (state, response) => {
      if (state) {
        window.localStorage.setItem(
          "storeTalyva",
          JSON.stringify(response.data)
        );
        window.location.assign("/");
        this.setState({
          message: {
            state: true,
            msg: response.message,
            type: "success",
            isloading: false,
          },
        });
      } else {
        this.setState({
          message: {
            state: true,
            msg: response,
            type: "danger",
            isloading: false,
          },
        });
      }
    });
  };
  render() {
    var { message } = this.state;

    return (
      <>
        <form
          class="col-lg-3 col-md-4 col-10 mx-auto text-center"
          style={{ marginTop: "130px" }}
          onSubmit={this.onLogin}
        >
          <div classNameName="container">
            {message.state && (
              <div
                class={
                  message.type === "danger"
                    ? "alert alert-danger alert-dismissible fade show"
                    : "alert alert-success alert-dismissible fade show"
                }
                role="alert"
              >
                {message.msg}
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            )}
          </div>
          <a class="navbar-brand mx-auto mt-2 flex-fill text-center" href="/">
            <svg
              version="1.1"
              id="logo"
              class="navbar-brand-img brand-md"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 120 120"
              xmlSpace="preserve"
            >
              <g>
                <polygon class="st0" points="78,105 15,105 24,87 87,87 	" />
                <polygon class="st0" points="96,69 33,69 42,51 105,51 	" />
                <polygon class="st0" points="78,33 15,33 24,15 87,15 	" />
              </g>
            </svg>
          </a>
          <h1 class="h5 mb-3">Authentification</h1>
          <div class="form-group">
            <label for="inputEmail" class="sr-only">
              Nom D'utilisateur
            </label>
            <input
              type="text"
              id="inputEmail"
              class="form-control form-control-lg"
              placeholder="Nom d'utilisateur"
              required=""
              autofocus=""
              name="username"
              onChange={(e) => {
                this.handlechange(e);
              }}
            />
          </div>
          <div class="form-group">
            <label for="inputPassword" class="sr-only">
              Password
            </label>
            <input
              type="password"
              id="inputPassword"
              class="form-control form-control-lg"
              placeholder="Mot de passe"
              required=""
              name="password"
              onChange={(e) => {
                this.handlechange(e);
              }}
            />
          </div>

          <button
            class="btn btn-lg btn-primary btn-block"
            type="submit"
            disabled={message.isloading}
          >
            {message.isloading ? (
              <>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {"   "}
                Patientez
              </>
            ) : (
              <span>Connexion</span>
            )}
          </button>
          <p class="mt-5 mb-3 text-muted">© Talyva 2022</p>
        </form>
      </>
    );
  }
}

export default Login;
