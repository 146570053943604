/*
    Renvoi l'url de l'api
*/
export const _api = () => {
  //return "http://localhost:5550/api/talyva/v1";
  return "https://api-talyva.onrender.com/api/talyva/v1";
};

/**
 * Verifie si un objet n'est pas vide
 * @param {*} object
 * @returns
 */
export const IsNotEmpty = (object) => {
  let flag = false;

  for (const value in object) {
    if (
      object[value] !== "" &&
      // object[value] !== null &&
      object.hasOwnProperty(value)
    ) {
      flag = true;
    } else {
      flag = false;
      break;
    }
  }

  return flag;
};

/**
 * Renvoi les informations stockées dans le store
 * @returns
 */
export const _Store = () => {
  var datas = localStorage.storeTalyva
    ? JSON.parse(localStorage.storeTalyva)
    : null;
  return datas;
};

/**
 * Fonction permettant de deconncter un user
 * @param {*} e
 */
export const _logout = (e) => {
  e.preventDefault();
  localStorage.removeItem("storeTalyva");
  if (!localStorage.removeItem("storeTalyva")) {
    window.location.assign("/login");
  } else {
    localStorage.removeItem("storeTalyva");
  }
};

/**
 * Converti une date
 * @param {*} date
 * @returns
 */
